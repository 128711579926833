/*
 * @Autor: Baowang
 * @Date: 2023-06-27 19:55:44
 * @pageName: 
 * @LastEditTime: 2023-07-19 15:31:55
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import request from '../utils/request'
// import { resolve } from 'core-js/fn/promise';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    activeIndex:'1',//页面导航的索引
    currenpathName:'',//当前的路径
    manageIndex:0,//企业管理页面数据
    config:"",//公共的配置
    userInfo:"",//用户信息
    keyWord:"",//搜索字段
    friendlyInfo:"",//底部详情
    ruhui:"",
    isSwipered:'1',//是否显示轮播图
  },
  mutations: {
    setNavindex(state,value){
      // 更新导航跳转索引
      state.activeIndex=value
    },
    setpathName(sate,value){
      // 更新当前路径name
      sate.currenpathName=value
    },
    setQiyeManage(state,value){
      // 设置企业管理导航
      state.manageIndex=value
    },
    setCongig(sate,value){
      // 设置公共的配置
      sate.config=value
    },
    setUserinfo(sate,value){
      // 设置用户信息
      sate.userInfo=value
    },
    setkeyword(sate,value){
      // 设置搜素字段
      sate.keyWord = value
    },
    setfriendly(sate,value){
      // 设置外链
      sate.friendlyInfo=value
    },
    setruhui(sate,value){
      sate.ruhui=value
    },
    setSwiper(sate,value){
      // 控制是否显示轮播图
      sate.isSwipered=value
    }
  },
  actions: {
    getConfig(context){
      request({
        url:"/api/user/logo_introduce",
        method:"POST",
        data:{}
      }).then(res=>{
        // console.log(res,'999999')
        if(res.code==1){
          context.commit("setCongig",res.data)
        }
      })
    },
    getfriendly(context){
      // 请求外链地址
      request({
        url: "/api/index/friendly_links",
        method: "POST",
        data: {},
      }).then(res=>{
        // console.log(res,'999999')
        if(res.code==1){
          context.commit("setfriendly",res.data)
        }
      })
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]  
})
