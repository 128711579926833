/*
 * @Autor: Baowang
 * @Date: 2023-06-27 19:55:44
 * @pageName: 主配置
 * @LastEditTime: 2023-07-04 18:38:45
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './common/css/base.less'
import request from './utils/request'

import router from './router'
import store from './store'
import disPublic from './utils/disPublic' 
import './utils/rem'
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$api = request
Vue.prototype.$http = disPublic

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
