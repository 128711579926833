/*
 * @Autor: Baowang
 * @Date: 2023-07-04 18:31:22
 * @pageName: 
 * @LastEditTime: 2024-10-10 10:26:14
 */
import { Message } from 'element-ui';
export const publicFunction = {
	testphone: new RegExp(/^1(3|4|5|6|7|8|9)[0-9]{9}$/), //验证手机号
	testpass: new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,18}/), //验证密码 字母数字、特殊字符
	testpassword: new RegExp(/^[\w]{6,18}$/), //验证密码 6-18位 字母、数字和下划线
	testcard: new RegExp(/^(\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/), //身份证
	noClick: true,
	imageArray: [], //上传图片数组
	mapH5: "BJOBZ-3XL6P-WIUDN-LJGSB-PNSP6-OZFTT",
	toast: function(titles) {
		Message({
            showClose: true,
            message: titles,
            type: 'warning'
          })
	},
    noMultipleClicks: function(methods, title) {
        // 处理多次点击事件
		let that = this;
		if (that.noClick) {
			that.noClick = false;
			methods();
			setTimeout(function() {
				that.noClick = true;
			}, 3000)
		} else {
			console.log("请稍后点击")
		}
	},
	zhuanyi(value,number){
		if(number){
			return Number(value)
		}else{
			return value?value:''
		}
	}
}
export default publicFunction;