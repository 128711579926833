/*
 * @Autor: Baowang
 * @Date: 2023-07-03 17:39:29
 * @pageName: 配置域名文件
 * @LastEditTime: 2024-11-11 09:21:11
 */
export default {
	// HOST:"https://nongye.yunqizhongguo.com",//接口测试域名
	HOST:"https://www.szailea.org.cn",//接口测试域名
	Global: {
		baseUrl: {
			// apiUrl: "https://nongye.yunqizhongguo.com",//图片域名
			apiUrl: "https://www.szailea.org.cn",//图片域名
		},
		style:{
			mainColor:'#4783F9'
		}
	}
}