<!--
 * @Autor: Baowang
 * @Date: 2023-06-27 19:55:44
 * @pageName: 
 * @LastEditTime: 2024-05-15 16:12:08
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // console.log("123456", this.$store.state.activeIndex);
    this.$store.dispatch("getConfig");
    this.$store.dispatch("getfriendly");
  },
};
</script>
<style scoped>
b {
  font-weight: bold;
}
</style>
<style lang="less">
#app {
  font-family: "Microsoft YaHei", "Segoe UI", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
